import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { pushTagOnProductViewButtonClick } from "app/utils/analytics";
import ImageWithoutCarousel from "app/pages/SmartDP/Listing/SmartDPProduct/ImagesCarousel/ImageWithoutCarousel";
import ImagesCarouselPP from "app/pages/SmartDP/Listing/SmartDPProduct/ImagesCarousel/ImagesCarouselPP";
import ProductTopBar from "app/pages/Listing/Product/ProductTopBar/ProductTopBar";
import ProductDestinationTitleContainer from "app/pages/.shared/ProductDestinationTitle/ProductDestinationTitleContainer";
import ProductNameWithCategory from "app/pages/.shared/ProductNameWithCategory/ProductNameWithCategory";
import ProductRatingContainer from "app/pages/FicheProduit/components/ProductRating/ProductRatingContainer";
import ProductLinkContainer from "app/pages/.shared/ProductLink/ProductLinkContainer";
import IconRight from "app/pages/.shared/static/icons/IconRight";
import "./ProductCard.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import {
	BADGE_CODES,
	FLAGSHIP_EVENT_KPIS,
	OFFER_CONTRACT_TYPES,
	PRODUCT_EXPERIENCES_WITH_HOTEL,
} from "app/constants";
import ProductCardPricing from "app/pages/.shared/ProductCard/ProductCardPricing";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { addOptionsToCloundinaryURL } from "app/utils/image/cloudinaryUtils";
import { EventCategory, HitType, useFlagship } from "@flagship.io/react-sdk";
import { useLocation } from "react-router-dom";

const IMG_SIZES = {
	small: { width: 768, height: 416 },
	medium: { width: 768, height: 416 },
	large: { width: 502, height: 282 },
	xlarge: { width: 502, height: 282 },
};

// Code pour ab testing
// export default props => {
// 	const productCardDesignFlag = useFsFlag("product-card-design", "");
// 	const productCardDesignValue = productCardDesignFlag.getValue();
//
// 	switch (productCardDesignValue) {
// 		case "new-price-left":
// 		case "new-price-right":
// 			return (
// 				<ProductCard
// 					{...props}
// 					isPriceRight={productCardDesignValue === "new-price-right"}
// 				/>
// 			);
// 		default:
// 			return <ProductOriginal {...props} />;
// 	}
// };

const ProductCard = ({
	isMobile,
	position,
	product = {},
	travelByTrainAvailable,
	displayCardSliderOnMob = true,
	isPriceRight = false,
}) => {
	const { hit: fsHit } = useFlagship();
	const { pathname } = useLocation();

	const handleProductClick = useCallback(() => {
		fsHit.send({
			type: HitType.EVENT,
			category: EventCategory.ACTION_TRACKING,
			action: FLAGSHIP_EVENT_KPIS.CLICK_ON_PP_PRODUCT_CARD,
			label: pathname,
		});
		pushTagOnProductViewButtonClick(product, position + 1);
	}, [product.uri, position, pathname]);

	const hastripAdvisor = product.tripadvisor?.reviewsCount > 0;

	const shouldCropDestination = !isPriceRight || (isPriceRight && hastripAdvisor);
	const shouldCropProductName = !isPriceRight && hastripAdvisor;

	const productClassname = classNames({
		"product-card": true,
		"product-card--new-price-right": isPriceRight,
		"product-card--crop-destination": shouldCropDestination,
		"product-card--crop-name": shouldCropProductName,
	});

	const openInNewTab = !isMobile;

	// TODO a refacto une fois que l'on connait la variation de la product card gagnante
	const badgeCoupDeCoeur = product?.badges?.find(
		badge => badge.code === BADGE_CODES.COUP_DE_COEUR
	);

	// on doit avoir une nouvelle reference pour badgeCoupDeCoeurBlack pour ne pas avoir un coeur noir issue d'une variation B ou C pour une variation original
	const badgeCoupDeCoeurBlack = badgeCoupDeCoeur
		? {
				...badgeCoupDeCoeur,
		  }
		: undefined;

	if (badgeCoupDeCoeurBlack) {
		// TODO forcer le picto noir du coeur dans cette variation. La couleur doit etre setup dans tourop normalement
		badgeCoupDeCoeurBlack.pictoUrl = addOptionsToCloundinaryURL(
			"https://res.cloudinary.com/perfectstay/image/upload/v1476467741/icons/icon-coeur-black.svg"
		);
	}

	const badgesToDisplay = product?.badges?.filter(
		badge => badge.code !== BADGE_CODES.COUP_DE_COEUR
	);

	return (
		<div className={productClassname} data-testid="product">
			<div className="product-card__picture">
				{!displayCardSliderOnMob ? (
					<ImageWithoutCarousel
						handleProductClick={handleProductClick}
						product={product}
						openInNewTab={openInNewTab}
						isMobile={isMobile}
					/>
				) : (
					<ImagesCarouselPP
						sizes={IMG_SIZES}
						images={product?.photos}
						alt={product?.name}
						productUri={product?.uri}
						handleProductClick={handleProductClick}
						openInNewTab={openInNewTab}
					/>
				)}

				<div className="product-card__topbar">
					<ProductTopBar
						badges={badgeCoupDeCoeurBlack ? [badgeCoupDeCoeurBlack] : []}
						isFlashsale={product?.isFlashsale}
						productExpirationHours={product?.expiration?.hour}
						productExpirationDays={product?.expiration?.day}
					/>
				</div>
			</div>
			<div className="product-card__footer">
				<ProductLinkContainer
					handleProductClick={handleProductClick}
					productUri={product.uri}
					openOnNewTab={openInNewTab}
				>
					<div className="product-card__container">
						<div className="product-card__info product-card__info--left">
							<Typography
								variant={TYPOGRAPHY_VARIANTS.XL}
								variantMD={TYPOGRAPHY_VARIANTS.XL}
								isBold
							>
								<ProductDestinationTitleContainer
									country={product.country}
									region={product.region}
									resort={product.resort}
								/>
							</Typography>
							<Typography
								variant={TYPOGRAPHY_VARIANTS.REGULAR}
								variantMD={TYPOGRAPHY_VARIANTS.REGULAR}
								isBold
							>
								<ProductNameWithCategory
									categoryType={product.categoryType}
									category={product.category}
									name={product.name}
								/>
							</Typography>

							<ProductRatingContainer
								tripAdvisor={product.tripadvisor || {}}
								holidayCheck={{
									...(product.holidaycheck || {}),
									size: "small",
								}}
								isCompact={isMobile}
							/>
						</div>

						<div className="product-card__info product-card__info--right">
							<ProductCardPricing
								travelByTrainAvailable={travelByTrainAvailable}
								isRental={product?.contractType === OFFER_CONTRACT_TYPES.RENTAL}
								includeOfferWithFlight={product?.includeOfferWithFlight}
								duration={product?.fromPriceType?.numberOfNights}
								publicPrice={product?.fromPriceType?.publicPrice}
								price={product?.fromPriceType?.price}
								hour={product?.expiration?.hour}
								day={product?.expiration?.day}
								type={product?.fromPriceType?.type}
								value={product?.fromPriceType?.value}
								isWithHotel={PRODUCT_EXPERIENCES_WITH_HOTEL.includes(
									product?.productExperience
								)}
							/>
							<div className="product-card__action">
								{isMobile ? (
									<IconRight width={20} height={20} />
								) : (
									<Typography variant={TYPOGRAPHY_VARIANTS.LARGE} isBold>
										<FormattedMessage id="sdp.product.cta.label" />
									</Typography>
								)}
							</div>
						</div>
					</div>
					{badgesToDisplay?.length > 0 && (
						<div className="product-card__badges">
							{badgesToDisplay.map((badge, idx) => {
								return (
									<span key={idx}>
										{badge.label}
										{idx < badgesToDisplay.length - 1 && (
											<span className="product-card__badge-separator">|</span>
										)}
									</span>
								);
							})}
						</div>
					)}
				</ProductLinkContainer>
			</div>
		</div>
	);
};

ProductCard.propTypes = {
	isMobile: PropTypes.bool,
	position: PropTypes.number,
	product: PropTypes.object,
	travelByTrainAvailable: PropTypes.bool,
	displayCardSliderOnMob: PropTypes.bool,
	isPriceRight: PropTypes.bool,
};

export default memo(ProductCard);
