const IconRight = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
		<path
			d="M5.5293 11.06L8.58263 8L5.5293 4.94L6.4693 4L10.4693 8L6.4693 12L5.5293 11.06Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconRight;
