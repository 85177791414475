function IconPlane2(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
			<path
				d="M5.33335 14.3333H6.66669L10 9H13.6667C14.22 9 14.6667 8.55334 14.6667 8C14.6667 7.44667 14.22 7 13.6667 7H10L6.66669 1.66667L5.33335 1.66667L7.00002 7H3.33335L2.33335 5.66667H1.33335L2.00002 8L1.33335 10.3333H2.33335L3.33335 9H7.00002L5.33335 14.3333Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default IconPlane2;
